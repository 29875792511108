import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { GridLayoutContainerWithSpacing2 } from '../gridLayout';
import { theme } from '../../theming';
import type { DetailSectionWrapperProps } from './types';

export const DetailSectionWrapper: React.FC<DetailSectionWrapperProps> = ({
  children,
  title,
  titleColor = theme.palette.secondary.dark,
  withoutDivider = false,
  ref
}) => (
  <GridLayoutContainerWithSpacing2 ref={ref}>
    <Grid item>
      <Typography variant="subtitle2" color={titleColor}>
        {title}
      </Typography>
    </Grid>
    {children}
    {!withoutDivider && (
      <Grid item width="100%">
        <Divider sx={{ width: '100%' }} />
      </Grid>
    )}
  </GridLayoutContainerWithSpacing2>
);

DetailSectionWrapper.displayName = 'DetailSectionWrapper';
