import { DragIndicator } from '@mui/icons-material';
import { alpha, styled, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { theme } from '@linx-ui/shared/theming';

const TableStyled = styled(Table)(({ theme }) => {
  return {
    width: 'auto',
    '.MuiTableCell-root': {
      position: 'relative',
      borderBottom: 0,
      '& .columnSeperator': {
        position: 'absolute',
        right: '0',
        top: '35%',
        height: '30%',
        width: '2px',
        backgroundColor: theme.palette.text.disabled,
        userSelect: 'none',
        touchAction: 'none',
        '&:hover': {
          backgroundColor: theme.palette.getContrastText(theme.palette.background.default)
        }
      },
      '.unsorted': {
        opacity: 0
      },
      ':hover': {
        '.unsorted': {
          opacity: 0.5
        }
      }
    },
    '.columnSeperator.isResizing': {
      backgroundColor: theme.palette.getContrastText(theme.palette.background.default)
    },
    'tbody .MuiTableRow-root:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    tbody: {
      '.MuiTableCell-root': {
        padding: theme.spacing(1, 1)
      }
    },
    thead: {
      '.MuiTableCell-root': {
        padding: theme.spacing(1, 1)
      },
      '.MuiIconButton-root': {
        padding: theme.spacing(0)
      },
      '.MuiTableCell-root:last-child': {
        width: '100%'
      }
    },
    'thead .MuiTableRow-root:not(:last-child)': {
      position: 'relative',
      '::after': {
        content: '""',
        left: 0,
        bottom: 0,
        height: '1px',
        width: '100%',
        position: 'absolute',
        background: theme.palette.divider
      }
    }
  };
});

const TableHeadStyled = styled(TableHead)(({ theme }) => {
  return {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    '::after': {
      content: '""',
      bottom: '0',
      height: '1px',
      width: '100%',
      position: 'absolute',
      backgroundColor: theme.palette.divider
    },
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.background.lighter : theme.palette.background.default
  };
});

const TableContainerStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noData' && prop !== 'toolbarHeight'
})<{
  noData?: boolean;
  toolbarHeight: number;
}>(({ theme, toolbarHeight }) => {
  const height = toolbarHeight + 8;
  return {
    position: 'relative',
    overflow: 'auto',
    maxWidth: '100%',
    minHeight: '100px',
    maxHeight: `calc(100% - ${height}px)`,
    border: `1px solid ${theme.palette.divider}`
  };
});

const HeaderCellWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'clickable'
})<{ width?: number; clickable?: boolean; isPlaceholder?: boolean }>(({ width, clickable }) => {
  return {
    width: `${width}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: clickable ? 'pointer' : undefined
  };
});

const InnerHeaderCellWrapper = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center'
  };
});

const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'clickable'
})<{ clickable: boolean; disabled: boolean }>(({ clickable, theme, disabled }) => {
  return {
    cursor: clickable ? 'pointer' : 'inherit',
    backgroundColor: !disabled ? theme.palette.background.paper : alpha(theme.palette.action.disabledBackground, 0.01),
    '&:hover': {
      backgroundColor: !disabled
        ? theme.palette.action.hover
        : `${alpha(theme.palette.action.disabledBackground, 0.01)} !important`
    }
  };
});

const TabelCellPadder = styled(TableCell)<{ height: number }>(({ height }) => {
  return {
    height: `${height}px`
  };
});

const TableCellContent = styled('div')<{ width: number }>(({ width }) => {
  return {
    width: `${width}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '.select-one-checkbox': {
      padding: 0
    }
  };
});

const NoResultFound = styled('div')<{ width?: number }>(() => {
  return {};
});

const TableCellNotFound = styled(TableCell)<{ width?: number }>(({ theme }) => {
  return {
    height: '42px',
    backgroundColor: theme.palette.background.paper
  };
});

const TableCellLoading = styled(TableCell)(() => {
  return {
    padding: '0 !important'
  };
});

const ResizerStyled = styled('div')<{ isResizable?: boolean }>(({ isResizable }) => {
  return {
    cursor: isResizable ? 'ew-resize' : 'default'
  };
});

const HeaderTableCell = styled(TableCell)<{ transform?: string }>(({ theme, width, transform }) => {
  return {
    width: `${width}px`,
    position: 'relative',
    transform,
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.dark,
    '.select-all-checkbox': {
      padding: 0
    }
  };
});

const RowTableCell = styled(TableCell)<{ width: number; transform?: string }>(({ width, transform }) => {
  return {
    width: `${width}px`,
    position: 'relative',
    transform,
    transition: 'width transform 0.2s ease-in-out'
  };
});

const TableWrapper = styled('div')(() => {
  return {
    width: '100%',
    height: '100%'
  };
});

const TableToolbar = styled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: 0,
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5)
  };
});

const DragIndicatorStyled = () => <DragIndicator fontSize="small" style={{ color: theme.palette.text.disabled }} />;

export {
  DragIndicatorStyled,
  HeaderCellWrapper,
  HeaderTableCell,
  InnerHeaderCellWrapper,
  NoResultFound,
  ResizerStyled,
  RowTableCell,
  TabelCellPadder,
  TableCellContent,
  TableCellLoading,
  TableCellNotFound,
  TableContainerStyled,
  TableHeadStyled,
  TableRowStyled,
  TableStyled,
  TableToolbar,
  TableWrapper
};
